import { Controls } from "../../components/Controls";
import QuotePicture from '../../assets/images/quote.svg';
import styles from './Intro.module.css';

export const Intro = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}></div>
      <div className={styles.right}>
        <div className={styles.title}>
          <div className={styles.textWrapper}>
            <div className={styles.title__text}>С праздником,</div>
            <div className={styles.title__subtext}>
              <span className={styles.selected}>м</span>
              ужчины!
            </div>
          </div>
          <div className={styles.title__date}>
            <div className={`${styles.title__day} ${styles.selected}`}>23</div>
            <div className={styles.title__month}> / 02</div>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.main__image}>
            <img alt='' src={QuotePicture} />
          </div>
          <div className={styles.main__text}>
            Поздравляю вас с Днем защитника Отечества!<br/>
            Будьте здоровы, счастливы, удачливы и непобедимы.
            Пусть каждый день дарит заряд позитива и бодрости во всём,
            а мужество и сила воли ведут далеко вперед. Горжусь, что работаю с вами!
            Зелёных тестов, лёгких алертов, стабильного интернета и наикрутейших задач :)
          </div>
          <div className={styles.main__image__last}>
            <img alt='' src={QuotePicture} />
          </div>
        </div>
        <div className={styles.signature}>
          С наилучшими пожеланиями, &nbsp;<span className={styles.selected}>Л</span>иза
        </div>
        <div className={styles.explain}>
          У меня есть для вас небольшой&nbsp;
          <span className={styles.selected}>подарок</span>.
          Но чтобы его получить, нужно <span className={styles.striked}>пострадать</span>&nbsp; кое-что сделать.
          Введи свою фамилию на русском языке с заглавной буквы. Например, “Иванов”
        </div>
        <Controls />
      </div>
    </div>
  );
};
