import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Topline } from './components/Topline';
import { Intro } from './pages/Intro';
import { Finish } from './pages/Finish';
import './App.css';

export const App = () => (
  <BrowserRouter>
    <Topline />
    <div className='content'>
      <Switch>
        <Route path='/' exact render={(props) => <Intro {...props}/>}/>
        <Route path='/finish' render={(props) => <Finish {...props}/>}/>
      </Switch>
    </div>
  </BrowserRouter>
);
