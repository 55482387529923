import styles from './Finish.module.css';

export const Finish = () => (
  <div className={styles.wrapper}>
    <div className={styles.right}>
      <div className={styles.title}>
        <div className={styles.textWrapper}>
          <div className={styles.title__text}>Условия использования</div>
          <div className={styles.title__subtext}>
            <span className={styles.selected}>п</span>
            ромокода
          </div>
        </div>
        <div className={styles.title__date}>
          <div className={`${styles.title__day} ${styles.selected}`}>23</div>
          <div className={styles.title__month}> / 02</div>
        </div>
      </div>
      <div className={styles.main}>
        <div>
          Данный промокод, к сожалению, имеет срок действия. Используйте его
          <span className={styles.selected}>&nbsp;до 20 марта</span> текущего года.
        </div>
        <div>
          Акция действует по следующим <span className={styles.selected}>адресам</span>:
          <ul>
            <li>Москва, ул. Новослободская, д. 16</li>
            <li>Москва, ул. Петровские Линии, д. 2/18</li>
            <li>Москва, 1-я Тверская-Ямская, д. 7 (23 февраля, 8 марта купон не действует)</li>
            <li>Москва, ул. Городецкая, д. 5 (23 февраля, 8 марта купон не действует)</li>
          </ul>
        </div>
        <div>
          Скидка <span className={styles.selected}>не распространяется на</span>&nbsp;
          «Стейк мясника», стейк «Рибай», стейк «Тамерлан», лосося по-чайхански, лосося
          на пару, каре ягненка, «Бургер №1», напитки, заказы навынос, доставку,
          паровые коктейли, бизнес-ланчи и другие спецпредложения ресторанов, в том числе сезонное меню.
        </div>
        <div>
          Нельзя использовать купон и скидочную карту <span className={styles.selected}> одновременно</span>.
        </div>
        <div>
          Купон действует на <span className={styles.selected}>одного</span> человека.
        </div>
        <div>
          По купонам обслуживаются компании <span className={styles.selected}>до 8 человек включительно</span>.
        </div>
        <div>
          Каждым купоном можно воспользоваться <span className={styles.selected}> только один раз</span>.
        </div>
        <div>
          Забронируйте столик по телефону, сообщите <span className={styles.selected}>номер и код купона</span>.
        </div>
        <div>
          <span className={styles.selected}>Предъявите</span> распечатанный или электронный купон перед заказом
          (печатная версия купона будет выслана в Telegram).
        </div>
        <div>
          Информацию по условиям акции <span className={styles.selected}>можно уточнить</span> по телефонам компании:
          <ul>
            <li>+7 (499) 973-13-50 (ул. Новослободская, д. 16)</li>
            <li>+7 (495) 790-00-32 (ул. Петровские Линии, д. 2/18)</li>
            <li>+7 (499) 251-67-61 (1-я Тверская-Ямская, д. 7)</li>
            <li>+7 (499) 746-63-14 (ул. Городецкая, д. 5)</li>
          </ul>
        </div>
      </div>
      <div className={styles.signature}>
        С наилучшими пожеланиями, &nbsp;<span className={styles.selected}>Л</span>иза
      </div>
    </div>
  </div>
);
