import { useState } from 'react';

import styles from './Controls.module.css';

const GUYS = {
  'Столяров': process.env.REACT_APP_CODE_STOLYAROV,
  'Косоуров': process.env.REACT_APP_CODE_KOSOUROV,
  'Свиридов': process.env.REACT_APP_CODE_SVIRIDOV,
  'Тимонин': process.env.REACT_APP_CODE_TIMONIN,
  'Казаков': process.env.REACT_APP_CODE_KAZAKOV,
  'Тупицин': process.env.REACT_APP_CODE_TUPICIN,
  'Стернин': process.env.REACT_APP_CODE_STERNIN,
  'Сытых': process.env.REACT_APP_CODE_SITIH,
  'Тюрин': process.env.REACT_APP_CODE_TURIN,
  'Зеленецкий': process.env.REACT_APP_CODE_ZELENECKIY,
  'Щетинников': process.env.REACT_APP_CODE_SHETINNIKOV,
  'Чалик': process.env.REACT_APP_CODE_CHALIK,
  'Ткаликов': process.env.REACT_APP_CODE_TKALIKOV,
  'Липко': process.env.REACT_APP_CODE_LIPKO,
  'Добрянская': process.env.REACT_APP_CODE_DOBRYANSKAYA,
};

export const Controls = () => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    setValue(event.target.value);
    setError('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!Object.keys(GUYS).includes(value)) {
      setError('Проверьте корректность введенной фамилии');
      return;
    }

    const link = GUYS[value];
    window.open(link, '_self');
  };

  return (
    <>
      <div className={styles.wrapper}>
        <input
          className={styles.input}
          placeholder='Твоя фамилия...'
          type='text'
          value={value}
          onChange={handleInputChange}
        />
        <button
          className={styles.button}
          type="submit"
          onClick={handleSubmit}
        >
          Получить подарок
        </button>
      </div>
      {error && (
        <div className={styles.error}>{error}</div>
      )}
    </>
  );
};
